<template>
  <div>
    <div class="title-bar">
      <h1 v-if="isViewingSelf">Skill Review</h1>
      <h1 v-else-if="userViewing !== null">{{ userViewing.name }}'s Skill Review</h1>
    </div>
    <div class="review-content">
      <SkillReviewList :userEmailViewing="selectedUser"/>
    </div>
  </div>
</template>

<script>
import SkillReviewList from "./components/SkillReviewList.vue";
import userDataAccess from '../../dataAccess/userDataAccess';

export default {
  name: "skillreview",
  components: {
    SkillReviewList
  },
  data() {
    return {
      userViewing: null,
      userDAO: new userDataAccess(),
      isViewingSelf: false
    }
  },
  methods: {
    async initView() {
      try {
        let currUser = await this.userDAO.getAccount();
        if(this.selectedUser === undefined || this.selectedUser === "" || this.selectedUser == currUser.email){
          this.isViewingSelf = true;
          this.userViewing = currUser;
          return;
        }
      } catch (err) {
        console.log(err);
        return;
      }

      this.isViewingSelf = false;
      try {
        this.userViewing = await this.userDAO.getAccount(this.selectedUser);
      } catch (err) {
        console.log(err);
        return;
      }
    }
  },
  computed: {
    selectedUser() {
        return this.$route.query.user;
    },
    selectedUserEmail() {
        if (this.userViewing === null) {
            return "";
        }
        return this.selectedUser;
    },
  },
  mounted: async function() {
      await this.initView();
  }
};
</script>

<style scoped>
.review-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
</style>