<template>
  <div class="skill-review-list">
    <v-card class="title-bar darker">
      <div class="d-flex justify-between align-center">
        <h1>Review Skills</h1>
      </div>
      <p
        class="description grey--text"
      >Make sure your skills are up to date by reviewing older skills.</p>
    </v-card>

    <v-list class="proficiency-list" v-if="oldProficiencies.length !== 0">
      <div v-for="prof in oldProficiencies" :key="prof.id">
        <div class="proficiency-actions">
          <v-btn :color="isOldProficiency(prof) ? colors.PRIMARY : 'green'" class="white--text review-button" @click.once="reviewProficiency(prof)">
            {{isOldProficiency(prof) ? "Review" : "Reviewed"}} 
            <v-icon class="review-check-icon">check_circle_outline</v-icon>
          </v-btn>
          <ProficiencySlider :canEdit="true" :proficiency="prof" :saveOnChange="true" :showReviewedDate="true" class="proficiency-slider"/>
        </div>
        <v-divider class="proficiency-divider"></v-divider>
      </div>
    </v-list>
    
    <h3 class="no-remaining" v-else-if="!isLoadingList">No un-reviewed skills remaining! Congrats!</h3>
    
    <v-progress-circular
      v-if="isLoadingList"
      indeterminate
      :color="colors.PRIMARY"
      class="initialization-progress"
      :size="50"
      :width="3"
    ></v-progress-circular>
  </div>
</template>

<script>
import colors from "@/colors";
import proficiencyDataAccess from "@/dataAccess/proficiencyDataAccess";
import ProficiencySlider from "../../Skills/components/ProficiencySlider.vue";

export default {
  name: "skillreviewlist",
  components: {
    ProficiencySlider
  },
  data(){
    return {
      colors,
      proficiencyDAO: new proficiencyDataAccess(),
      proficiencies: [],
      isLoadingList: true
    }
  },
  props: ["userEmailViewing"],
  mounted: async function() {
    await this.initializeList();
  },
  methods: {
    async initializeList() {
      this.proficiencies = await this.proficiencyDAO.getProficiencies(this.userEmailViewing);

      this.isLoadingList = false;
    },
    isOldProficiency(proficiency){
      return new Date().getTime() - new Date(proficiency.reviewedDate).getTime() > 7776000000; //7776000000 is the amount of ticks in 90 days
    },
    async reviewProficiency(proficiency){
      await this.proficiencyDAO.patchProficiencyReviewDate({
        id: proficiency.id,
        reviewedDate: new Date().toLocaleDateString()
      });

      proficiency.reviewedDate = new Date().toLocaleDateString();
    }
  },
  computed: {
    oldProficiencies(){
      let tmpProficiencies = [...this.proficiencies];

      tmpProficiencies.sort((prof1, prof2) => {
        return new Date(prof1.reviewedDate) - new Date(prof2.reviewedDate)
      });

      return tmpProficiencies.filter(prof => {
        return this.isOldProficiency(prof);
      });
    }
  }
}
</script>

<style scoped>
.title-bar{
  position: relative;
  z-index: 0;
}
.skill-review-list{
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  min-width: 350px;
  max-width: 1000px;
  width: 70%;
  background-color: #fefefe;
  margin: auto 20px;
  margin-top: 20px;
}

.initialization-progress{
  margin-top: 100px;
  margin-bottom: 100px;
}

.proficiency-list{
  padding-bottom: 0;
}

.proficiency-actions{
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 100%;
}

.proficiency-slider{
  width: 100%;
}

.review-button{
  margin-left: 15px;
  width: 130px;
}

.review-check-icon{
  margin-left: 15px;
}

.no-remaining{
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: 1250px){
  .skill-review-list{
    width: 95%;
  }
}

@media screen and (max-width: 650px){
  .skill-review-list{
    margin: 20px 0px;
    width: 100%;
  }

  .proficiency-actions{
    flex-direction: column-reverse;
    align-items:flex-start;
  }

  .review-button{
    margin-top: -5px;
    margin-bottom: 15px;
  }
}
</style>